<template>
  <div>
    <div v-if="userType === 'public'">
      <v-row justify="center">
        <v-col cols="12" md="10" lg="8">
          <v-banner>
            <v-icon slot="icon" color="warning" size="36">
              mdi-account-alert-outline
            </v-icon>
            Sign in to view {{ business.name }} schedules.
            <template v-slot:actions>
              <v-btn color="primary" text @click="showSignIn = true">
                Sign In
              </v-btn>
            </template>
            <sign-in-dialog
              v-if="showSignIn"
              @close="showSignIn = false"
            ></sign-in-dialog>
          </v-banner>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="userType === 'guest'">
      <v-row justify="center">
        <v-col cols="12" md="10" lg="8">
          <v-banner>
            <v-icon slot="icon" color="warning" size="36">
              mdi-account-alert-outline
            </v-icon>
            <div class="d-flex clickable" @click="expand = !expand">
              <span class="font-weight-bold py-2">
                Are you one of the {{ business.custtype.toLowerCase() }} of
                {{ business.name }}?
              </span>
              <v-spacer></v-spacer>
              <v-icon>{{
                expand ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </div>
            <v-expand-transition>
              <div v-show="expand">
                <p>
                  If yes, your FeePlus account has not been linked. Check with
                  {{ business.name }} and make sure your registered email is the
                  same with their customer record to join an event.
                </p>
              </div>
            </v-expand-transition>
          </v-banner>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showSignIn: false,
      expand: false,
    }
  },
  async mounted() {
    if (!this.customer.id) {
      await this.$store.dispatch("fetchCustomer");
    }
  },
  computed: {
    business() {
      return this.$store.state.business;
    },
    customer() {
      return this.$store.state.customer;
    },
    userType() {
      return this.$store.getters.userType();
    },
  },
  components: {
    SignInDialog: () =>
      import(
        /* webpackChunkName: "SignInDialog" */ "@/components/SignInDialog"
      ),
  },
}
</script>