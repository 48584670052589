<template>
<check-authorization>
<v-card
    max-width="450"
    class="mx-auto"
  >
    <v-toolbar
      color="blue"
      dark
    >
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
      <!-- <v-avatar color="indigo" size="45">
            <img
              v-if="requestor.picture_link"
              :src="requestor.picture_link"
            />
            <v-icon v-else class="white--text">mdi-account-circle</v-icon>
          </v-avatar> -->

      <v-toolbar-title>Notes</v-toolbar-title>

      <!-- <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn> -->
    </v-toolbar>

    <v-card-title>
            <v-avatar size="56">
              <img
              v-if="requestor.picture_link"
              :src="requestor.picture_link"
            />
            <v-icon v-else class="white--text">mdi-account-circle</v-icon>
            </v-avatar>
            <p class="ml-3">
              {{ requestor.fullname }}
            </p>
          </v-card-title>

    <v-list two-line>
      <template v-for="(note, index) in notes">
        <v-divider
          :key="'d-'+index"
        ></v-divider>
        <!-- :inset="item.inset" -->

        <v-list-item
          :key="index"
          link
          @click="viewNote(note.id)"
        >
          <v-list-item-avatar>
            <v-icon v-if="note.note_type==2" class="grey lighten-1" dark>mdi-chart-line</v-icon>
            <v-icon v-else-if="note.note_type==3" class="grey lighten-1" dark>mdi-bell</v-icon>
            <v-icon v-else-if="note.note_type==4" class="grey lighten-1" dark>mdi-heart-pulse</v-icon>
            <v-icon v-else-if="note.note_type==5" class="grey lighten-1" dark>mdi-file-table</v-icon>
            <v-icon v-else class="grey lighten-1" dark>mdi-file-document-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-html="note.note_title"></v-list-item-title>
            <v-list-item-subtitle v-html="note.note"></v-list-item-subtitle>
            <v-list-item-subtitle v-html="note.note_datetime"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
  <note-dialog
    v-if="showNote"
    :cid="cid"
    :cnid="cnid"
    @close="showNote = false"
  ></note-dialog>
</check-authorization>
</template>
<script>
// TODO: Attendance list
// import Navigation from "@/components/Navigation";
import CheckAuthorization from "@/components/CheckAuthorization";
// import MyNote from "@/components/MyNote";
import dateTimeMixin from "@/mixins/dateTimeMixin";

export default {
  props: {
    cid: {
      required: true,
    },
  },
  data() {
    return {
      hasError: false,
      loaded: false,
      requestor: {
        fullname: "",
      },
      showNote: false,
      cnid: null,
    };
  },
  async mounted() {
    // if (!this.customer.id) {
    //   await this.$store.dispatch("fetchCustomer");
    // }
    // if (!this.service.service_id) {
    //   await this.$store.dispatch("fetchCustomerServices");
    // }
    // if (!this.service_records || !this.service_records.length) {
    //   await this.$store.dispatch({
    //     type: "fetchServiceCustomerRecords", 
    //     scid: this.scid,
    //     dcid: this.isDependant ? this.cid : null
    //   });
    // }
    if (this.customer.id) {
      if(this.getRequestor()){
        // NOTES
        await this.fetchNotes();
        console.log('notes', this.notes);
        // if (!this.events || !this.events.length) {
        //   await this.fetchEventsJoinList();
        // } else {
        //   this.processEvents(this.events);
        // }
      }
    } else {
      await this.$store.dispatch("fetchCustomer");
    }
    this.loaded = true;
  },
  methods: {
    async fetchNotes() {
      return await this.$store.dispatch({
        type: "fetchNotes",
        cid: this.cid,
        dcid: this.isDependant ? this.cid : null,
      });
    },
    async viewNote(id){
      // view note
      // fetch note
      this.cnid = id;
      await this.$store.dispatch({
        type: "fetchNote",
        cnid: id,
        cid: this.cid,
        dcid: this.isDependant ? this.cid : null,
      });
      this.showNote = true
    },
    getRequestor() {
      let found = false;
      if (this.customer.id && this.cid !== this.requestor.id) {
        if (this.cid == this.customer.id) {
          this.requestor = this.customer;
          found = true;
        } else if(this.customer.dependants) {
          this.customer.dependants.forEach((dependant) => {
            if (this.cid == dependant.id) {
              this.requestor = dependant;
              found = true;
            }
          });
        }
      }
      if(found) {
        return this.requestor;
      } else {
        this.$router.replace({ 
          name: "MyNotes",
          params: {
            cid: this.customer.id
          }
        })
        return false
      }
    },
    // refreshServiceRecords() {
    //   this.$store.dispatch({
    //     type: "fetchServiceCustomerRecords", 
    //     scid: this.scid,
    //     dcid: this.isDependant ? this.cid : null
    //   });
    // }
  },
  computed: {
    customer() {
      return this.$store.state.customer;
    },
    isDependant() {
      return this.$store.getters.isDependant(this.cid);
    },
    notes() {
      return this.$store.state.notes[this.cid];
    },
  },
  watch: {
    customer(n, o) {
      if (n.id !== o.id) {
        if(this.getRequestor()) this.fetchNotes();
      }
    },
  },
  components: {
    // Navigation,
    CheckAuthorization,
    NoteDialog: () =>
      import(
        /* webpackChunkName: "NoteDialog" */ "@/components/MyNote"
      ),
  },
  mixins: [dateTimeMixin],
};
</script>
