<template>
  <div>
    <!-- <Navigation :show-back="true" />
    <v-main>
      <v-container> -->
    <div v-if="userType === 'public'">
      <v-row justify="center">
        <v-col cols="12" md="10" lg="8">
          <v-banner>
            <v-icon slot="icon" color="warning" size="36">
              mdi-account-alert-outline
            </v-icon>
            Sign in to view {{ business.name }} schedules.
            <template v-slot:actions>
              <v-btn color="primary" text @click="showSignIn = true">
                Sign In
              </v-btn>
            </template>
            <sign-in-dialog
              v-if="showSignIn"
              @close="showSignIn = false"
            ></sign-in-dialog>
          </v-banner>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="userType === 'guest'">
      <v-row justify="center">
        <v-col cols="12" md="10" lg="8">
          <v-banner>
            <v-icon slot="icon" color="warning" size="36">
              mdi-account-alert-outline
            </v-icon>
            <div class="d-flex clickable" @click="expand = !expand">
              <span class="font-weight-bold py-2">
                Are you one of the {{ business.custtype.toLowerCase() }} of
                {{ business.name }}?
              </span>
              <v-spacer></v-spacer>
              <v-icon>{{
                expand ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </div>
            <v-expand-transition>
              <div v-show="expand">
                <p>
                  If yes, your FeePlus account has not been linked. Check with
                  {{ business.name }} and make sure your registered email is the
                  same with their customer record to join an event.
                </p>
              </div>
            </v-expand-transition>
          </v-banner>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-stepper v-model="e6" vertical>
        <v-list-item>
          <v-list-item-avatar color="indigo" size="45">
            <img
              v-if="requestor.picture_link"
              :src="requestor.picture_link"
            />
            <!-- <span v-else-if="user.initial" class="white--text headline">{{
                user.initial
              }}</span> -->
            <v-icon v-else class="white--text">mdi-account-circle</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline">{{
              requestor.fullname
            }}</v-list-item-title>
            <!-- <v-list-item-subtitle>Join Event</v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>
        <v-stepper-step :complete="e6 > 1" step="1">
          Select an event
          <small v-if="selectedData.event" class="d-inline">{{
            selectedData.event.event_title
          }}</small>
          <!-- <small>Summarize if needed</small> -->
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card color="" class="mb-12">

            <v-dialog
              ref="modalDatePicker"
              v-model="modalDatePicker"
              :return-value.sync="pickerDate"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="info"
                  v-bind="attrs"
                  v-on="on"
                  class="mb-2"
                >
                  <v-icon left>
                    mdi-calendar
                  </v-icon>
                  Calendar
                </v-btn>
              </template>
              <v-date-picker
                v-model="pickerDate"
                scrollable
                :allowed-dates="val => eventsDates.find(date => date.date == val)"
                @change="saveDatePicker"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modalDatePicker = false"
                >
                  Close
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-tabs
              v-model="tab"
              background-color="info"
              dark
            >
              <v-tab
                v-for="date in eventsDates"
                :key="date.date"
              >
                {{ date.title }}
              </v-tab>
            </v-tabs>

            <v-list>
              <v-list-item-group v-model="selected.event" color="primary">
            <v-tabs-items v-model="tab">
              <v-tab-item
                v-for="(date, index) in eventsDates"
                :key="index"
              >
                <v-card flat>
                  <v-card-text>
                    
                    <template v-for="(event, index) in eventsList.slice(date.indexFrom, date.indexTo + 1)">
                      <v-list-item
                        :key="event.event_id + '.' + event.parent_event_instance_id"
                        :value="event.index"
                        :disabled="event.chip || event.is_cancelled ? true : false"
                      >
                        <template v-slot:default="{ active }">
                          <v-list-item-content>
                            <v-list-item-title class="d-sm-flex">
                              <h3 class="event-title">{{ event.event_title }}</h3>
                              <v-spacer></v-spacer>
                              <div
                                v-text="
                                  toTimeRange(event.start_time, event.end_time)
                                "
                              ></div>
                            </v-list-item-title>
                            <div class="d-flex flex-wrap">
                              <v-chip v-if="event.locname" color="primary">{{
                                event.locname
                              }}</v-chip>
                              <v-chip
                                v-if="event.required_passes"
                                color="green"
                                text-color="white"
                                ><v-icon left small>mdi-ticket-confirmation</v-icon>
                                Require Pass
                                <v-avatar right class="green darken-4">
                                  {{ event.required_passes }}
                                </v-avatar>
                              </v-chip>
                              <v-chip v-if="event.capacity"
                                :color="(event.capacity - event.participants_count) > 0 ? 'success' : 'red'"
                                text-color="white"
                              >
                                {{ event.participants_count > event.capacity ? 0 : event.capacity - event.participants_count }}/{{ event.capacity }} Available
                              </v-chip>
                              <v-chip
                                v-if="event.is_cancelled"
                                color="red"
                                dark
                                class="ml-auto"
                              >
                                {{
                                  event.is_rescheduled ? "Rescheduled" : "Cancelled"
                                }}
                                <v-icon right>mdi-calendar-remove</v-icon>
                              </v-chip>
                              <v-chip
                                v-else-if="event.chip"
                                :color="event.chip.color"
                                dark
                                class="ml-auto"
                              >
                                {{ event.chip.label }}
                                <v-icon right>{{ event.chip.icon }}</v-icon>
                              </v-chip>
                              <v-chip
                                v-else-if="event.parent_event_instance_id"
                                color="indigo"
                                dark
                                class="ml-auto"
                              >
                                Rescheduled
                                <v-icon right>mdi-calendar-check</v-icon>
                              </v-chip>
                            </div>
                          </v-list-item-content>
                          <v-list-item-action>
                            <div v-if="!event.chip && !event.is_cancelled">
                              <v-icon v-if="!active" color="grey lighten-1">
                                mdi-checkbox-blank-outline
                              </v-icon>

                              <v-icon v-else color="green">
                                mdi-checkbox-marked
                              </v-icon>
                            </div>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                      <v-divider :key="index"></v-divider>
                    </template>
                      
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
            </v-list-item-group>
            </v-list>

          </v-card>

          <v-btn color="primary" @click="validateStepper(1)">Continue</v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 2" step="2"
          >Select passes
          <small v-if="selectedData.pass" class="d-inline">{{
            selectedData.pass.service_name
          }}</small>
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card color="" class="mb-12">
            <v-alert v-if="!selectedData.event.required_passes" type="success">
              No passes is required for this schedule. Click continue.
            </v-alert>
            <v-list>
              <v-list-item-group v-model="selected.pass" color="primary">
                <template v-for="(pass, index) in passesList">
                  <v-list-item
                    :key="pass.service_record_id"
                    :disabled="!pass.allowed"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title class="d-sm-flex">
                          <h3 class="event-title">{{ pass.service_name }}</h3>
                          <v-spacer></v-spacer>
                          <div>
                            Balance {{ pass.passes_counter }}
                            <v-icon>mdi-ticket</v-icon>
                          </div>
                        </v-list-item-title>
                        <div class="d-flex flex-wrap">
                          <div class="flex-column">
                            <div v-if="pass.invoice_no">
                              (Invoice No: {{ pass.invoice_no }})
                            </div>
                            <div>
                              Expired: {{ toDate(pass.service_dateto) }}
                            </div>
                          </div>
                          <v-chip
                            v-if="!pass.allowed"
                            color="red"
                            dark
                            class="ml-auto"
                          >
                            Not Applicable
                            <v-icon right>mdi-cancel</v-icon>
                          </v-chip>
                          <v-chip
                            v-else-if="'required_passes' in pass"
                            color="green"
                            text-color="white"
                            class="ml-auto"
                            ><v-icon left small>mdi-ticket-confirmation</v-icon>
                            Require Pass
                            <v-avatar right class="green darken-4">
                              {{ pass.required_passes }}
                            </v-avatar>
                          </v-chip>
                        </div>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div v-if="pass.allowed">
                          <v-icon v-if="!active" color="grey lighten-1">
                            mdi-checkbox-blank-outline
                          </v-icon>

                          <v-icon v-else color="green">
                            mdi-checkbox-marked
                          </v-icon>
                        </div>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                  <v-divider :key="index"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-btn color="primary" @click="validateStepper(2)">Continue</v-btn>
          <v-btn text @click="e6 = 1">Previous</v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 3" step="3">Summary</v-stepper-step>

        <v-stepper-content step="3">
          <v-card color="grey lighten-1" class="mb-12">
            <v-simple-table>
              <template>
                <tbody>
                  <tr>
                    <th>Event</th>
                    <td>{{ selectedData.event.event_title }}</td>
                  </tr>
                  <tr>
                    <th>Date & Time</th>
                    <td>
                      {{ toDayDate(selectedData.event.start_time) }}<br />{{
                        toTimeRange(
                          selectedData.event.start_time,
                          selectedData.event.end_time
                        )
                      }}
                    </td>
                  </tr>
                  <tr v-if="selectedData.event.required_passes">
                    <th>No of pass required</th>
                    <td>{{ selectedData.pass.passes_to_deduct }}</td>
                    <!-- // selectedData.event.required_passes -->
                  </tr>
                  <tr v-if="selectedData.event.required_passes">
                    <th>Pass used</th>
                    <td>
                      {{ selectedData.pass.service_name }} (Invoice No:
                      {{ selectedData.pass.invoice_no }})
                    </td>
                  </tr>
                  <tr v-if="selectedData.event.required_passes">
                    <th>Pass Balance</th>
                    <td>
                      {{ selectedData.pass.passes_balance }}
                      <!-- // selectedData.pass.passes_counter -
                        //   selectedData.event.required_passes -->
                    </td>
                  </tr>
                  <tr>
                    <th>Location</th>
                    <td>{{ selectedData.event.locname }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          <div class="d-flex flex-wrap">
            <v-btn color="success" @click="joinEvent">Ready to Join</v-btn>
            <v-btn text @click="e6 = 2">Previous</v-btn>
            <v-btn color="warning" class="ml-auto" @click="resetStepper"
              >Reset</v-btn
            >
          </div>
        </v-stepper-content>
      </v-stepper>
    </div>
    <!-- </v-container>
    </v-main> -->
  </div>
</template>
<script>
// import Navigation from "@/components/Navigation";
import moment from "moment";
import dateTimeMixin from "@/mixins/dateTimeMixin";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      showSignIn: false,
      expand: false,
      selected: {
        event: null,
        pass: null,
      },
      e6: 1,
      loaded: false,
      selectedData: {
        event: {},
        pass: {},
      },
      eventsList: [],
      eventsList2: [], // v2 - 20220127
      eventsDates: [], // v2 - 20220128
      passesList: null,
      requestor: {
        fullname: "",
      },
      tab: null, // v2 - 20220127
      modalDatePicker: false,
      pickerDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    };
  },
  props: {
    cid: {
      required: true,
    },
    event_id: {
      default: null,
    },
    event_date: {
      default: null,
    },
  },
  async mounted() {
    if (this.customer.id) {
      if(this.getRequestor()){
        if (!this.events || !this.events.length) {
          await this.fetchEventsJoinList();
        } else {
          this.processEvents(this.events);
        }
      }
    } else {
      await this.$store.dispatch("fetchCustomer");
    }
    // if (!this.customer.id) {
    //   await this.$store.dispatch("fetchCustomer");
    // }
    //   if (!this.events || !this.events.length) {
    //     // await this.$store.dispatch({
    //     //   type: "fetchEventsJoinList",
    //     //   cid: this.cid,
    //     //   dcid: this.isDependant ? this.cid : null,
    //     //   startdate: moment().format("YYYY-MM-DD"),
    //     //   enddate: moment()
    //     //     .add(5, "days")
    //     //     .format("YYYY-MM-DD"),
    //     // });
    //     try {
    //       await this.fetchEventsJoinList();
    //     } catch (e) {
    //       console.log("error", e);
    //     }
    //   } else {
    //     this.processEvents(this.events);
    //   }
    // if(this.userType == 'customer'){

    // }
    this.loaded = true;
  },

  methods: {
    async fetchEventsJoinList() {
      return await this.$store.dispatch({
        type: "fetchEventsJoinList",
        cid: this.cid,
        dcid: this.isDependant ? this.cid : null,
        startdate: moment().format("YYYY-MM-DD"),
        enddate: moment()
          .add(1, "month").endOf('month') // .add(29, "days") // 20231121 - change from 6
          .format("YYYY-MM-DD"),
      });
    },
    processEvents(newValue) {
      let events = [],
        dateTemp = "",
        data = JSON.parse(JSON.stringify(newValue)); // deep copying object
      if (data) {
        let index = 0,
          dates = []; // v2 - 20220128;
        data
          .sort(function(a, b) {
            return moment(a.start_time).diff(moment(b.start_time));
          })
          .forEach((a) => {
            let theDate = moment(a.start_time).format("YYYY-MM-DD");
            if (theDate !== dateTemp) {
              // events.push({
              //   header: this.isToday(theDate)
              //     ? "Today"
              //     : moment(theDate, "YYYY-MM-DD").format("dddd, MMMM D, YYYY"),
              // }); // v2 - 20220128 - only add events
              if(dates.length) dates[dates.length - 1].indexTo = index - 1 // set the previous dates indexTo
              dates.push({
                date: theDate, 
                title: this.isToday(theDate) ? "Today" : moment(theDate, "YYYY-MM-DD").format("dddd, MMMM D, YYYY"),
                indexFrom: index,
                indexTo: null
              })
              dateTemp = theDate;
            }
            // check participation
            a.chip = this.getParticipationData(
              a.participant_status,
              a.participant_type //a.is_service_customer
            );
            // check capacity
            if (
              !a.chip &&
              a.capacity > 0
            ) {
              if(a.participants_count >= a.capacity) {
                a.chip = {
                  color: "red",
                  icon: "mdi-calendar-alert",
                  label: "Fully Booked",
                };
              }
            }
            // if(!a.chip && !a.is_cancelled){
            //   this.allowedEvents.push()
            // }
            // select event
            if (
              this.event_id &&
              this.event_date &&
              a.event_id == this.event_id &&
              a.event_date == this.event_date
            ) {
              this.selected.event = index;
              this.tab = dates.length - 1
            }
            a.index = index;
            events.push(a);
            index++;
          });
        if(dates.length) dates[dates.length - 1].indexTo = index - 1 // set the previous dates indexTo
        this.eventsDates = dates;
        this.eventsList = events;
      } else this.eventsList = data;
    },
    getParticipationData(status, participant_type) {
      switch (status) {
        case -1:
          return {
            color: "red",
            icon: "mdi-close-circle-outline",
            label: "Took Leave",
          };
        case 0:
          return {
            color: "red",
            icon: "mdi-close-circle-outline",
            label: "Absent",
          };
        case 1:
          return {
            color: "green",
            icon: "mdi-check-circle-outline",
            label: "Attend",
          };
        case 3:
          return {
            color: "success",
            icon: "mdi-checkbox-marked-circle-outline",
            label: "Approved",
          };
        case 8: // Requested
          return {
            color: "primary",
            icon: "mdi-clock-check-outline",
            label: "Awaiting Approval",
          };
        // case 2: // added - means enrolled
        default:
          if (status == 2 || participant_type === 0 || participant_type === 1) {
            return {
              color: "info",
              icon: "mdi-calendar-check",
              label: "Enrolled",
            };
          } else return null;
      }
    },
    async validateStepper(step) {
      switch (step) {
        case 1: // select event
          if (typeof this.selected.event === "number") {
            this.selectedData.event = this.eventsList[this.selected.event];
            if (this.selectedData.event.required_passes) {
              if (this.passesList == null) {
                let data = await this.$store.dispatch({
                  type: "fetchUserPasses",
                  dcid: this.isDependant ? this.cid : null,
                });
                this.passesList = data.passes;
              }
              // check passes if got restriction
              let restrict_services = JSON.parse(
                this.selectedData.event.restrict_services
              );
              if (restrict_services.length) {
                // got restriction
                // console.log('rs_passes', this.selectedData.event.rs_passes, typeof this.selectedData.event.rs_passes)
                let rs_passes = JSON.parse(this.selectedData.event.rs_passes),
                  got_rs_passes = Object.keys(rs_passes).length;
                // console.log('rs_passes', rs_passes);
                // console.log('got_rs_passes', got_rs_passes);
                this.passesList.map(
                  (p) => {
                    // console.log('p.service_dateto', p.service_dateto);
                    // console.log('this.selectedData.event.event_date', this.selectedData.event.event_date);
                    p.allowed = restrict_services.indexOf(p.service_id) !== -1 && moment(p.service_dateto).isSameOrAfter(this.selectedData.event.event_date);
                    if(got_rs_passes) p.required_passes = (String(p.service_id) in rs_passes) ? rs_passes[String(p.service_id)] : this.selectedData.event.required_passes; // 20240221
                    return p;
                  }
                );
              } else {
                this.passesList.map((p) => (p.allowed = true));
              }
              this.e6 = 2;
            } else {
              this.selectedData.pass.service_name = "No pass require";
              this.e6 = 3;
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Please select an event to continue.",
            });
          }
          break;
        case 2: // select pass
          if (this.selectedData.event.required_passes) {
            if (typeof this.selected.pass === "number") {
              this.selectedData.pass = this.passesList[this.selected.pass];
              this.selectedData.pass.passes_to_deduct = 'required_passes' in this.selectedData.pass ? this.selectedData.pass.required_passes : this.selectedData.event.required_passes;
              this.selectedData.pass.passes_balance = this.selectedData.pass.passes_counter - this.selectedData.pass.passes_to_deduct;
              if(this.selectedData.pass.passes_balance < 0){
                Swal.fire({
                  icon: "error",
                  title: `Selected passes (${this.selectedData.pass.service_name}) is insufficient to be deducted - ${this.selectedData.pass.passes_to_deduct} required but only ${this.selectedData.pass.passes_counter} left.`
                });
              } else {
                this.e6 = 3;
              }
            } else {
              Swal.fire({
                icon: "error",
                title:
                  "Selected event require pass to join. Please select a pass to continue.",
              });
            }
          } else {
            this.e6 = 3;
          }
      }
    },
    joinEvent() {
      let data = {
        event_id: this.selectedData.event.event_id,
        event_date: this.selectedData.event.event_date,
        customer_id: this.isDependant ? this.cid : this.customer.id, //this.selectedData.event.customer_id,
        service_record_id: this.selectedData.pass.service_record_id,
        passes: this.selectedData.pass.passes_to_deduct, // this.selectedData.event.required_passes,
      }; 
      this.$store
        .dispatch({
          type: "createEventJoin",
          dcid: this.isDependant ? this.cid : null,
          data: data,
        })
        .then((r) => {
          if (r.error == false) {
            this.$store.dispatch("fetchEvents");
            this.fetchEventsJoinList();
            if (this.selectedData.event.required_passes)
              this.$store.dispatch("fetchCustomer"); // to update available active passes
            Swal.fire({
              icon: "success",
              title: "Join request submitted",
              // onClose: () => {
              //   this.$router.push({
              //     name: "HomeMain",
              //   });
              //   this.resetStepper();
              //   // this.$store.dispatch({
              //   //   type: 'deleteState',
              //   //   key: 'events_join_list',
              //   //   id: this.cid
              //   // });
              // },
            }).then(() => {
              this.$router.push({
                  name: "HomeMain",
                });
              this.resetStepper();
            });
          } else {
            Swal.fire({
              icon: "error",
              text: r.message
            });
          }
        });
    },
    getRequestor() {
      let found = false;
      if (this.customer.id && this.cid !== this.requestor.id) {
        if (this.cid == this.customer.id) {
          this.requestor = this.customer;
          found = true;
        } else if(this.customer.dependants) {
          this.customer.dependants.forEach((dependant) => {
            if (this.cid == dependant.id) {
              this.requestor = dependant;
              found = true;
            }
          });
        }
      }
      if(found) {
        return this.requestor;
      } else {
        this.$router.replace({ 
          name: "MyEvent",
          params: {
            cid: this.customer.id
          }
        })
        return false
      }
    },
    resetStepper() {
      this.passesList = null;
      this.selected.event = null;
      this.selected.pass = null;
      this.selectedData.event = {};
      this.selectedData.pass = {};
      this.e6 = 1;
    },
    saveDatePicker(date) {
      this.$refs.modalDatePicker.save(date)
      this.tab = this.eventsDates.findIndex(dateItem => dateItem.date == date)
    }
  },

  computed: {
    customer() {
      return this.$store.state.customer;
    },
    events() {
      return this.$store.state.events_join_list[this.cid];
    },
    isDependant() {
      return this.$store.getters.isDependant(this.cid);
    },
    business() {
      return this.$store.state.business;
    },
    userType() {
      return this.$store.getters.userType();
    },
  },

  watch: {
    customer(n, o) {
      if (n.id !== o.id) {
        if(this.getRequestor()) this.fetchEventsJoinList();
      }
    },
    events(newValue) {
      this.processEvents(newValue);
    },
    tab(newTab){
      this.pickerDate = this.eventsDates[newTab].date
    },
    // selected: {
    //   handler(newValue){
    //     console.log('selected changed', newValue.event)
    //   },
    //   deep: true
    // }
  },
  components: {
    SignInDialog: () =>
      import(
        /* webpackChunkName: "SignInDialog" */ "@/components/SignInDialog"
      ),
  },
  mixins: [dateTimeMixin],
};
</script>
<style scoped>
.event-title {
  white-space: pre-wrap;
}
.v-chip {
  margin: 2px;
}
table tr:hover {
  background-color: inherit !important;
}
.clickable:hover {
  color: #2196f3;
  cursor: pointer;
}
</style>
